import {logger} from '@mol-fe/mol-fe-client-logger';
import {initPaywall} from './init';
import {initLinkedFlow} from './linkAccounts/linkedFlow';
import {initProfile} from './profile';
import {initCustomerServicesLink} from './customerServices';
import {refreshAndStoreEntitlement} from './refreshEntitlement';
// eslint-disable-next-line import/no-unassigned-import
import './styles.scss';

export const load = async () => {
  try {
    await initPaywall();
  } catch (error) {
    logger.error('Error initialising mol-fe-paywall-loader initPaywall', error);
  }
};

export const loadLinkedFlow = async () => {
  try {
    await initLinkedFlow();
  } catch (error) {
    logger.error('Error initialising mol-fe-paywall-loader linkedFlow', error);
  }
};

export const loadProfile = async () => {
  try {
    await initProfile();
  } catch (error) {
    logger.error('Error initialising mol-fe-paywall-loader profile', error);
  }
};

export const loadCustomerServicesLink = () => {
  try {
    initCustomerServicesLink();
  } catch (error) {
    logger.error('Error initialising mol-fe-paywall-loader customer services link', error);
  }
};

export const loadRefreshEntitlement = async () => {
  try {
    await refreshAndStoreEntitlement();
  } catch (error) {
    logger.error('Error initialising mol-fe-paywall-loader refresh entitlement', error);
  }
};

load().catch(() => {});
loadLinkedFlow().catch(() => {});
loadProfile().catch(() => {});
loadRefreshEntitlement().catch(() => {});
loadCustomerServicesLink();
