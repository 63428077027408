import createCustomEvent from '../lib/createCustomEvent';

const DEFAULT_IMAGE_SRC = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const trigger = (element, eventName, params) => {
  element.dispatchEvent(createCustomEvent(eventName, params));
};

const lazyLoadImage = (parent) => {
  const images = parent.querySelectorAll('img[data-src]');

  Array.from(images).forEach((img) => {
    if (img && (!img.getAttribute('src') || img.getAttribute('src') === DEFAULT_IMAGE_SRC)) {
      img.setAttribute('src', img.getAttribute('data-src'));
    }
  });
};

const isEmpty = (selected) => !selected || selected.length === 0;

const sendGalleryMessage = (message) => {
  try {
    window.postMessage(
      {
        data: {
          message
        },
        scope: 'mol-fe-gallery'
      },
      window.location.href
    );
  } catch (error) {
    // Ignore
  }
};

export {
  lazyLoadImage,
  trigger,
  isEmpty,
  sendGalleryMessage
};
