/* eslint-disable id-match */
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {pageEvents} from '@mol-fe/mol-fe-tracking';
import URL from 'url-parse';
import * as zephrBrowser from '@zephr/browser';
import {getJwtTokenFromStorage} from './storage';
import {getEntitlementAndStore} from './api';
import {mountInlinePaywall} from './inlinePaywall';
import {mountTrending} from './trending';
import {waitForCMPDecision} from './cmp';

const parseVarValue = (value) =>
  value && value.trim().replace(/&amp;/g, '&').replace(/&apos;/g, '\'').replace(/&quot;/g, '"');

const parseOutcome = (outcomeEl) => {
  try {
    const outcomeType = outcomeEl.querySelector('[data-mol-fe-paywall-type]').innerHTML;
    const outcomeVarEls = Array.from(outcomeEl.querySelectorAll('[data-mol-fe-paywall-var]'));
    const outcomeVars = outcomeVarEls.reduce((acc, el) => {
      const key = el.dataset.molFePaywallVar;
      const isMultiline = el.dataset.molFePaywallVarType === 'multiline';
      const isNumber = el.dataset.molFePaywallVarType === 'number';
      let value = isMultiline ? el.innerHTML.split('\n').map(parseVarValue) : parseVarValue(el.innerHTML);

      if (isNumber) {
        value = Number(value);
      }

      return Object.assign({}, acc, {[key]: value});
    }, {});

    if (outcomeType === 'inline') {
      mountInlinePaywall(outcomeVars);
    }

    if (outcomeType === 'trending') {
      mountTrending(outcomeEl, outcomeVars);
    }
  } catch (error) {
    logger.error('Error parsing zephr outcome', error);
  }
};

const parseOutcomes = (outcomes) => {
  if (!outcomes) {
    return;
  }

  const outcomeElements = Array.from(document.querySelectorAll('[data-mol-fe-paywall-outcome]'));

  outcomeElements.forEach((outcomeEl) => parseOutcome(outcomeEl));
};

export const runZephr = async (customData) => {
  const parsedUrl = new URL(window.location.href, true);
  let jwt = parsedUrl.query.debugJwt || getJwtTokenFromStorage();

  if (!jwt) {
    const entitlementFromApi = await getEntitlementAndStore();

    if (entitlementFromApi && entitlementFromApi.jwtToken) {
      jwt = entitlementFromApi.jwtToken;
    }
  }

  try {
    // wait for CMP decision so as not to drop cookies before that
    await waitForCMPDecision();

    await zephrBrowser.run({
      customData,
      jwt: jwt || undefined
    });

    parseOutcomes(window.Zephr && window.Zephr.outcomes);
  } catch (error) {
    const {isPaywalled} = getPageCriteria();

    if (isPaywalled) {
      pageEvents.publish(pageEvents.PAYWALL_ZEPHR_FAIL_OPEN);
    }

    throw error;
  }
};
