(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mol-fe/mol-fe-sync-bundle")["molFeClientLogger"], require("@mol-fe/mol-fe-sync-bundle")["molFeTracking"], require("@mol-fe/mol-fe-sync-bundle")["molFePageMetadata"], require("@mol-fe/mol-fe-sync-bundle")["molFeAsync"], require("@mol-fe/mol-fe-sync-bundle")["molFeMobileutils"], require("@mol-fe/mol-fe-sync-bundle")["molFeDomHelpers"], require("@mol-fe/mol-fe-sync-bundle")["molFeComponents"], require("@mol-fe/mol-fe-sync-bundle")["molFeMiniLodash"]);
	else if(typeof define === 'function' && define.amd)
		define([, , , , , , , ], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@mol-fe/mol-fe-sync-bundle")["molFeClientLogger"], require("@mol-fe/mol-fe-sync-bundle")["molFeTracking"], require("@mol-fe/mol-fe-sync-bundle")["molFePageMetadata"], require("@mol-fe/mol-fe-sync-bundle")["molFeAsync"], require("@mol-fe/mol-fe-sync-bundle")["molFeMobileutils"], require("@mol-fe/mol-fe-sync-bundle")["molFeDomHelpers"], require("@mol-fe/mol-fe-sync-bundle")["molFeComponents"], require("@mol-fe/mol-fe-sync-bundle")["molFeMiniLodash"]) : factory(root["DM"]["molFeClientLogger"], root["DM"]["molFeTracking"], root["DM"]["molFePageMetadata"], root["DM"]["molFeAsync"], root["DM"]["molFeMobileutils"], root["DM"]["molFeDomHelpers"], root["DM"]["molFeComponents"], root["DM"]["molFeMiniLodash"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__28__, __WEBPACK_EXTERNAL_MODULE__29__) {
return 