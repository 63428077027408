import React from 'react';
import {render} from 'react-dom';
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import URL from 'url-parse';
import {InlinePaywall} from './components/InlinePaywall';
import {getArticleBody} from './article';
import {getPaywallOutcomeLabel} from './api';
import {getLoginLinkUrl, getRestorePurchaseUrl} from './config';

const MAX_ARTICLE_HEIGHT_DESKTOP = 300;
const MAX_ARTICLE_HEIGHT_MOBILE = 800;

const getMaxHeightByParagraphCount = (paragraphCount) => {
  const {renderPlatform} = getPageMetadata();
  const articleBody = getArticleBody();
  const articleMaxHeightDefault = renderPlatform === 'mobile' ? MAX_ARTICLE_HEIGHT_MOBILE : MAX_ARTICLE_HEIGHT_DESKTOP;

  if (!paragraphCount) {
    return articleMaxHeightDefault;
  }

  const paragraphs = Array.from(articleBody.querySelectorAll('p.mol-para-with-font:not(.imageCaption)'))
    .filter((element) => !element.closest('.mol-factbox'));

  if (!paragraphs.length) {
    return articleMaxHeightDefault;
  }

  let paragraphIdx = paragraphs.length < paragraphCount ? paragraphs.length - 1 : paragraphCount - 1;
  let cutoffParagraph = paragraphs[paragraphIdx];

  while (cutoffParagraph) {
    const bounding = cutoffParagraph.getBoundingClientRect();

    if (bounding.height > 0) {
      const topDiff = bounding.top - articleBody.getBoundingClientRect().top;

      return topDiff + cutoffParagraph.offsetHeight;
    }

    paragraphIdx++;
    cutoffParagraph = paragraphs[paragraphIdx];
  }

  return articleMaxHeightDefault;
};

const disableVideoFlyout = () => {
  if (window.DM && window.DM.molFeComponents && typeof window.DM.molFeComponents.setConfig === 'function') {
    window.DM.molFeComponents.setConfig('mol-fe-videoplayer', {
      plugins: {
        flyout: {
          enabled: false,
          forceFlyout: {
            enabled: false
          }
        }
      }
    });
  }
};

export const mountInlinePaywall = ({
  signUpTitle,
  signUpPretitle,
  signUpTopStripText,
  signUpUrl,
  signUpButtonText,
  signUpButtonSubtitle,
  signUpBullets,
  paragraphCount,
  theme,
  featureFlags
}) => {
  if (typeof signUpUrl !== 'string') {
    throw new TypeError(`invalid signUpUrl type. It must be a "string" but it is "${typeof signUpUrl}"`);
  }

  const mountElement = document.createElement('div');
  const articleBody = getArticleBody();

  if (!articleBody) {
    return;
  }

  const articleMaxHeight = getMaxHeightByParagraphCount(paragraphCount);
  const isLoggedIn = window.DM.isLoggedIn;
  const loginLinkUrl = getLoginLinkUrl();
  const restorePurchaseUrl = getRestorePurchaseUrl();
  const paywallOutcomeLabel = getPaywallOutcomeLabel();
  const {renderPlatform} = getPageMetadata();

  const url = new URL(signUpUrl, true);

  url.query.molclicksource = 'paywall';
  const parsedSignUpUrl = url.toString();

  mountElement.classList.add('noskim');
  articleBody.style.maxHeight = `${articleMaxHeight}px`;
  articleBody.style.overflow = 'hidden';
  articleBody.after(mountElement);
  articleBody.dataset.isInlinePaywallMounted = true;

  render(
    <InlinePaywall
      signUpTitle={signUpTitle}
      signUpPretitle={signUpPretitle}
      signUpTopStripText={signUpTopStripText}
      signUpUrl={parsedSignUpUrl}
      signUpButtonText={signUpButtonText}
      signUpButtonSubtitle={signUpButtonSubtitle}
      signUpBullets={signUpBullets}
      isLoggedIn={isLoggedIn}
      loginUrl={loginLinkUrl}
      restorePurchaseUrl={restorePurchaseUrl}
      theme={theme}
      paywallOutcomeLabel={paywallOutcomeLabel}
      renderPlatform={renderPlatform}
      featureFlags={featureFlags}
    />,
    mountElement
  );

  window.isInlinePaywallMounted = true;

  if (window.DM.molFeEnhancedGallery) {
    window.DM.molFeEnhancedGallery.closeGallery();
  }

  disableVideoFlyout();
};
