import {unmountComponentAtNode} from 'react-dom';
import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getFromStorage} from '../storage';

export const ESSENTIAL_ENTITLEMENT = 'mol-reject-all';
export const MAIL_PLUS_ENTITLEMENT = 'mailplus-basic';
export const AD_LITE_ENTITLEMENT = 'mailplus-ad-lite';
export const CAME_FROM_PURCHASE_ESSENTIAL = 'essential';
export const CAME_FROM_PURCHASE_MAIL_PLUS = 'mailPlus';
export const ESSENTIAL_HEADER_TEXT = 'You can now view MailOnline without \n seeing personalised ads';
export const CONVERSION_OUTCOMES = ['newUser', 'activationNeeded'];
export const PRIVACY_POLICY_PATH = '/home/article-7759273/Privacy-Cookies-Policy-Policy.html';
export const CMP_KEY = 'dmg.cmp.events';
const CONVERSION_ONE = 'cmp_level_1_conversion';
const CONVERSION_TWO = 'cmp_level_2_conversion';

export const PRODUCT_ESSENTIAL = 'mailessential';
export const PRODUCT_MAILPLUS = 'mailplus';
export const CMP_CONVERSION = [CONVERSION_ONE, CONVERSION_TWO];

export const onClickManageSettings = (event) => {
  event.preventDefault();

  try {
    window.adverts.queue.push({scope: 'openCmpBanner'});
  } catch (error) {
    logger.error('[mol-fe-paywall-loader] Essential feature, unable to open CMP Banner', error);
  }
};

export const getMountElement = () => {
  const mountElement = document.createElement('div');

  mountElement.classList.add('noskim');
  document.body.appendChild(mountElement);

  return mountElement;
};

export const handleUnmount = (mountElement) => {
  unmountComponentAtNode(mountElement);
  mountElement.remove();
};

export const cleanURL = (purchaseOutcomeStatus, parsedUrl) => {
  if (purchaseOutcomeStatus) {
    window.mailplusPurchaseOutcome = purchaseOutcomeStatus;
    delete parsedUrl.query.mailplusPurchaseOutcome;
    parsedUrl.set('query', parsedUrl.query);

    const newUrl = parsedUrl.toString();

    window.history.replaceState({}, document.title, newUrl);
  }
};

export const normaliseCameFromPurchase = (cameFromPurchaseStorage) => {
  const product = cameFromPurchaseStorage && cameFromPurchaseStorage.product ? cameFromPurchaseStorage.product : null;

  if (product === CAME_FROM_PURCHASE_ESSENTIAL) {
    return CAME_FROM_PURCHASE_ESSENTIAL;

    // legacy behaviour for mail plus purchase, cameFromPurchaseStorage value use to be set as true
  } else if (product === CAME_FROM_PURCHASE_MAIL_PLUS || cameFromPurchaseStorage === true) {
    return CAME_FROM_PURCHASE_MAIL_PLUS;
  }

  return null;
};

export const getMailPlusEmail = (userData) => {
  if (!userData || !userData.mailplusEmail) {
    return null;
  }

  return userData.mailplusEmail;
};

export const getEntitlementsCollection = (userData) => {
  if (userData && Array.isArray(userData.entitlements)) {
    return userData.entitlements;
  }

  return [];
};

export const handleConversionTrackingMailPlusProduct = (
  cameFromPurchase,
  purchaseOutcomeStatus,
  userHasMailPlus
) => {
  /**
   * 'purchase-meta' it means the user has clicked the inline paywall button found on m+ articles,
   *  masthead button or the button from the profile page. See referringPageType below.
   *  @type {{
   *    paywallOutcomeLabel: "Inline Paywall" | undefined
   *    referringModule: 'paywall' | 'profile' | 'masthead'
   *    referringPageType: 'paidArticle' | 'profile' | 'home'
   * }}
   */
  const purchaseMeta = getFromStorage('purchase-meta');
  const isPurchaseLoggedIn = purchaseOutcomeStatus === 'loggedIn' && userHasMailPlus;

  if (
    cameFromPurchase === CAME_FROM_PURCHASE_MAIL_PLUS &&
    purchaseOutcomeStatus &&
    (CONVERSION_OUTCOMES.includes(purchaseOutcomeStatus) || isPurchaseLoggedIn)
  ) {
    const isProfilePurchase = purchaseMeta && purchaseMeta.referringModule === 'profile';
    const isMastheadPurchase = purchaseMeta && purchaseMeta.referringModule === 'masthead';
    let conversionEvent = pageEvents.PAYWALL_CONVERSION;

    if (isProfilePurchase) {
      conversionEvent = pageEvents.PAYWALL_CONVERSION_PROFILE;
    } else if (isMastheadPurchase) {
      conversionEvent = pageEvents.PAYWALL_CONVERSION_BANNER;
    }

    pageEvents.publish(conversionEvent, purchaseMeta);
  }
};

export const isSubscribedToMailPlus = (entitlements) => entitlements.includes(MAIL_PLUS_ENTITLEMENT);

export const isSubscribedEssential = (entitlements) => entitlements.includes(ESSENTIAL_ENTITLEMENT);

export const hasAdEntitlement = (userData) => {
  if (userData && userData.entitlements && Array.isArray(userData.entitlements)) {
    const list = userData.entitlements;

    return list.includes(AD_LITE_ENTITLEMENT) || list.includes(ESSENTIAL_ENTITLEMENT);
  }

  return false;
};

export const hasCmpConversion = () => {
  try {
    const cmpData = JSON.parse(localStorage.getItem(CMP_KEY));

    return Array.isArray(cmpData) && (cmpData.includes(CONVERSION_ONE) || cmpData.includes(CONVERSION_TWO));
  } catch (error) {
    logger.error('Unable to check CMP conversion tracking records', error);
  }

  return false;
};

const cleanUpUsedCmpEvents = (input, used) => {
  const difference = input.filter((event) => !used.includes(event));

  if (difference.length) {
    localStorage.setItem(CMP_KEY, JSON.stringify(difference));
  } else {
    localStorage.removeItem(CMP_KEY);
  }
};

export const handleCmpTracking = (events) => {
  try {
    const cmpData = JSON.parse(localStorage.getItem(CMP_KEY));

    if (!Array.isArray(cmpData) || !cmpData.length) {
      return;
    }

    const firedEvents = [];

    events.forEach((event) => {
      if (cmpData.includes(event)) {
        switch (event) {
        case CONVERSION_ONE:
          pageEvents.publish(pageEvents.CMP_LEVEL_ONE_CONVERSION);
          break;
        case CONVERSION_TWO:
          pageEvents.publish(pageEvents.CMP_LEVEL_TWO_CONVERSION);
          break;
        default:
          break;
        }

        firedEvents.push(event);
      }
    });

    cleanUpUsedCmpEvents(cmpData, firedEvents);
  } catch (error) {
    logger.error('Unable to handle CMP tracking events for mail essential', error);
  }
};
